import $ from 'jquery';
import React from 'react';
import {getUserObjData} from '../function_lib/userToken';
import {appGetUrlPath} from '../function_lib/common_lib';
import './cart_canvas.css';
let cartItems;
let proData;
let finalAmt=0;
window.cAmount="0";
let uri;
let appUrtPath;
class Cart_Canvas extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
      RmElement(index){
        //alert("i m delete "+index);
        cartItems=localStorage.getItem("_cart");
        if(cartItems!=null && cartItems!==""){
           let getItem=JSON.parse(cartItems);
            var items=[];
            for(var i=0;i<getItem.length;i++){
                //alert(getItem[i].item_name);
                if(i!=index){
                    items.push({"item_name":getItem[i].item_name,"item_code":getItem[i].item_code,
                    "item_rate":getItem[i].item_rate,"company_name":getItem[i].company_name,"item_rf_id":getItem[i].item_rf_id,"img_c":getItem[i].img_c});
                }
             
              }
              localStorage.setItem("_cart",JSON.stringify(items));
              cartItems=localStorage.getItem("_cart");
              cartItems=JSON.parse(cartItems);
              //alert(cartItems.length);
             this.setState(state=>({setCartItems:cartItems,isUpdate:true}));
             finalAmt=0;
             $("._cart_elm_count").html("0");
             //alert("after delete = "+this.state.setCartItems.length)
             this.forceUpdateHandler();
              //window.location.reload();
        }
      }
      componentWillReceiveProps(props){
        cartItems=localStorage.getItem("_cart");
        if(cartItems!=null && cartItems!==""){
          cartItems=JSON.parse(cartItems);
          // alert(cartItems.length);
          this.setState(state=>({setCartItems:cartItems,isUpdate:true}));
          finalAmt=0;
          $("._cart_elm_count").html("0");
          this.forceUpdateHandler();
        }
        
      }
    constructor(props){
        super(props);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        appUrtPath=appGetUrlPath();
        this.state={setCartItems:"",isUpdate:false};
        uri="/login?rf=5cCsC5JlrxcTKe";
    var getUser=getUserObjData();
    
    if(getUser!==""){
      uri="/steptwo?rf="+getUser[0].id;
    }

    }
    componentDidMount(){
        cartItems=localStorage.getItem("_cart");
    if(cartItems!=null && cartItems!==""){
      
   
      cartItems=JSON.parse(cartItems);
      // alert(cartItems.length);
      this.setState(state=>({setCartItems:cartItems,isUpdate:true}));
      finalAmt=0;
      $("._cart_elm_count").html("0");
      this.forceUpdateHandler();
      //alert("checkcount=="+this.state.setCartItems.length+"="+this.state.isUpdate);
       if(cartItems.length!=0){
        //$("._cart_elm_count").html(cartItems.length); 
       }
    }
    }
    render(){
        return(<div id="offcanvas-cart" className="offcanvas offcanvas-cart">
<div className="inner">
    <div className="head">
        <span className="title">Cart</span>
        <button className="offcanvas-close">×</button>
    </div>
    <div className="body customScroll">
        <ul className="minicart-product-list">
        {(cartItems) ? cartItems.map((item,index)=>{
            
                     finalAmt+=parseFloat(item.item_rate);
                     window.cAmount=finalAmt;
                     $("._cart_elm_sun").html("₹"+finalAmt);
                     $("._cart_elm_count").html(index+1);
            return <li>
                <a href="javascript:void(0)" className="image"><img src={appUrtPath+item.img_c} alt={item.item_name} /></a>
                <div className="content">
                    <a href="javascript:void(0)" className="title">{item.item_name}</a>
                    <span className="quantity-price">1 x <span className="amount">₹{item.item_rate}</span></span>
                    <a href="javascript:void(0)" onClick={()=>this.RmElement(index)} param1={index} className="remove">×</a>
                </div>
            </li>
             }
             ):''
           }
        </ul>
    </div>
    <div className="foot">
        <div className="sub-total">
            <table className="table">
                <tbody>
                    <tr>
                        <td className="text-left">Sub-Total :</td>
                        <td className="text-right">₹{finalAmt}</td>
                    </tr>
                    <tr>
                        <td className="text-left">Total :</td>
                        <td className="text-right theme-color">₹{finalAmt}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="buttons">
            {/* <a href={uri} className="btn btn-dark btn-hover-primary mb-6">view cart</a> */}
            <a href={uri} className="btn btn-outline-dark current-btn">checkout</a>
        </div>
        <p className="minicart-message">Free Shipping on All Orders Over ₹100!</p>
    </div>
</div>
</div>);
    }
}



export default Cart_Canvas;