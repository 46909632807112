import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './left_img_txt_desc.css';
//import lefImg1 from '../assets/images/about-image/1.jpg';
export function Left_Img_Txt_Desc(lsData){
    var appPath= appGetUrlPath();
    var strResp="";
    let strBtn="";
    for(var i=0;i<lsData.length;i++){
        if(lsData[i]["col7"].trim()!==""){
            strBtn="<a type=\"button\" class=\"btn text-center btn-primary btn-hover-primary ml-4\" href=\""+lsData[i]["col7"]+"\">"+lsData[i]["col8"]+"</a>";
        }
//alert(lsData[i]["col7"].trim());
        if(i%2==0){
            strResp+="<section class=\"left_img_txt_desc border-box about-area pb-70px pt-70px\">"+
            "<div class=\"container\">"+
                "<div class=\"row align-items-center\">"+
                    "<div class=\"col-lg-6\">"+
                        "<div class=\"about-left-image mb-md-30px mb-lm-30px\" >"+
                            "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\" img-responsive w-100\" />"+
                        "</div>"+
                    "</div>"+
                    "<div class=\"col-lg-6\">"+
                        "<div class=\"about-content\">"+
                            "<div class=\"about-title\">"+
                                "<h3>"+lsData[i]["col1"]+"</h3>"+
                            "</div>"+
                            "<p class=\"mb-6\">"+
                               lsData[i]["col2"]+
                            "</p>"+
                            "<p>"+
                                strBtn+
                            "</p>"+
                        "</div>"+
                   "</div>"+
                "</div>"+
            "</div>"+
            "</section>"
        }else{
            strResp+="<section class=\"left_img_txt_desc border-box about-area pb-70px pt-70px\">"+
        "<div class=\"container\">"+
            "<div class=\"row align-items-center\">"+
               
                "<div class=\"col-lg-6\">"+
                    "<div class=\"about-content\">"+
                        "<div class=\"about-title\">"+
                            "<h3>"+lsData[i]["col1"]+"</h3>"+
                        "</div>"+
                        "<p class=\"mb-6\">"+
                           lsData[i]["col2"]+
                        "</p>"+
                        "<p>"+
                            strBtn+
                        "</p>"+
                    "</div>"+
               "</div>"+
               "<div class=\"col-lg-6\">"+
               "<div class=\"about-left-image mb-md-30px mb-lm-30px\">"+
                   "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\" img-responsive w-100\" />"+
               "</div>"+
           "</div>"+
            "</div>"+
        "</div>"+
        "</section>"
        }
        
    }
    return strResp;
}

export default Left_Img_Txt_Desc;