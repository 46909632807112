import  $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp,AjaxCallMethod,GetImgCertUser} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import Front_Header from './front_header';
import Footer from './footer';
import Cart_Canvas from '../sample_comp/cart_canvas';
import Product_Listing from '../sample_comp/product_listing';
import AOS from 'aos';
/* components */
import Top_Banner from '../sample_comp/top_banner';
import Features_Slider  from '../sample_comp/features_slider';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';
import './contact_us.css';

let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products=""
class Contact_Us extends React.Component{
    constructor(props){
        super(props);
        loc=props.location["pathname"].substr(1);
        if(localStorage.getItem("userData")!=null){
          //alert(localStorage.getItem("userData"));
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          if(userObject){
            //loginUser=userObject[0].person_name;
          }
          
          //window.location.href="./Dashboard";
        }
      }
    componentDidMount(){
        clsObject=this;
  var mapCat=[];
  mapCat.push({name:"curl_type",value:"getProducts"});
  mapCat.push({name:"res_func",value:"callBackProList"});
  mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
  mapCat.push({name:"p1",value:"0"});
  mapCat.push({name:"p2",value:"0"});
 // mapCat.push({name:"emp_id",value:userObject[0].id});
  mapCat["res_func"]="callBackProList";
  mapCat["curl_type"]="getProducts";
  CallMapMethod(clsObject,mapCat);
    }
    callBackpgData(data,msg){
       // alert("testing of2 "+data);
        //$("#pgComp").html(data);
        var listObj= JSON.parse("{\"access\":"+data+"}");
        //alert("successfully parse");
        var mainHt="";
        if(loc==="home"){
           // mainHt+=Video_Banner_Comp();
        }
        var injextI=0;
        var injCondition=false;
      
        for(var _dlist=0;_dlist<50;_dlist++){
         
            if(listObj.access[_dlist]){
                if(listObj.access[_dlist].top_banner){
                   // alert("I am in conditio");
                   injCondition=true;
                   mainHt+=Top_Banner(listObj.access[_dlist].top_banner);
                }
                else if(listObj.access[_dlist].home_banner){
                   // alert("i am in cond..");
                   injCondition=true;
                    mainHt+=Home_Banner(listObj.access[_dlist].home_banner);
                }else if(listObj.access[_dlist].dual_text_card){
                    injCondition=true;
                    //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
                   // mainHt+=Dual_Banner(listObj.access[_dlist].dual_text_card);
                }else if(listObj.access[_dlist].single_img_text){
                    injCondition=true;
                    mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
                }else if(listObj.access[_dlist].img_text_desc_img_left){
                    injCondition=true;
                    mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left);
                }else if(listObj.access[_dlist].text_description){
                    injCondition=true;
                    //alert("i am in text");
                    //alert(Txt_Description(listObj.access[_dlist].text_description));
                    mainHt+=Txt_Description(listObj.access[_dlist].text_description);
                }else if(listObj.access[_dlist].list_slider){
                    injCondition=true;
                    mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
                }
                if(injCondition){
                    injextI++;
                }
                //if(injextI==1)
                {
                    //alert("check in condi == "+loc)
                    //if(loc==="home"){
                       // mainHt+=Features_Slider();
                        //alert(products);
                       // mainHt+=products;
                   // }
               }
            }
    
          
            //$("#pgComp").innerHtml=mainHt;
        }
        if(listObj.access.length==0){
           // mainHt+=products;
        }
        //alert(mainHt);
        //mainHt+=Product_Hori_Slider();
        $("#pgComp").html(mainHt);
        RespBkMthdMapp(clsObject,"_addToCart","setCartBk");
        AOS.init();
        AOS.init({
          once: true
        });
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
          //this.forceUpdateHandler();
      
    }
    setCartBk(refId){
        // alert(refId);
         this.setState(state =>({viewData:refId}));
         this.forceUpdate();
         $("#offcanvas-cart").addClass("offcanvas-open");
       }
    callBackProList(data,msg){
    //alert("testing=="+data);
      if(data!=="0"){
       //var resPs= Product_Slider(data);
       var resPs=Product_Listing(data);
       //alert(resPs);
       products+=resPs;
      //$("#pgComp").append(resPs);
      }
      var pgMap=[];
      pgMap.push({name:"curl_type",value:"getPgData"});
      pgMap.push({name:"res_func",value:"callBackpgData"});
      pgMap.push({name:"loc",value:loc});
     // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
      //mapCat.push({name:"emp_id",value:userObject[0].id});
      pgMap["res_func"]="callBackpgData";
      pgMap["curl_type"]="getPgData";
      CallCMSMapMethod(clsObject,pgMap);
    }
    render(){
        return(<div>
             <Front_Header/>
             <div id="pgComp">
           </div>
           <div className="border-box contact-area pb-100px pt-100px">
        <div className="container">
        <div className="custom-row-2 row">
                <div className="col-lg-4 col-md-5 mb-lm-60px col-sm-12 col-xs-12 w-sm-100">
                    <div className="contact-info-wrap">
                        <h2 className="title" >Contact Us</h2>
                        <div className="single-contact-info" >
                            <div className="contact-info-inner">
                                <span className="sub-title">OFFICE</span>
                            </div>
                            <div className="contact-info-dec">
                                <p>23,Sector f,<br></br>Sanwer Road Industrial Area,<br></br>Indore MP - 452003</p>
                            </div>
                        </div>
                        <div className="single-contact-info" >
                            <div className="contact-info-inner">
                                <span className="sub-title">CONTACTS</span>
                            </div>
                            <div className="contact-info-dec">
                                <p><a href="tel:7746041623">+91 7746041623</a></p>
                            </div>
                        </div>
                        <div className="single-contact-info" >
                            <div className="contact-info-inner">
                                <span className="sub-title">EMAIL</span>
                            </div>
                            <div className="contact-info-dec">
                            <p><a href="mailto://contact@indoretourtravels.com">contact@indoretourtravels.com</a></p>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12">
                    <div className="contact-form">
                        <div className="contact-title mb-30">
                            <h2 className="title">Contact :Write to Us</h2>
                        </div>
                        <form className="contact-form-style" id="contact-form" action="https://htmlmail.hasthemes.com/nazmul/mail.php" method="post">
                            <div className="row">
                                <div className="col-lg-6" >
                                    <input name="name" placeholder="Name*" type="text" />
                                </div>
                                <div className="col-lg-6" >
                                    <input name="email" placeholder="Email*" type="email" />
                                </div>
                                <div className="col-lg-12" >
                                    <input name="subject" placeholder="Subject*" type="text" />
                                </div>
                              
                                <div className="col-lg-12" >
                                    <textarea name="message" placeholder="Your Message*"></textarea>
                                   
                            
                           
                                    <button className="btn btn-primary btn-hover-dark mt-4 btn-1"  type="submit">SEND</button>
                            
                                </div>
                            </div>
                        </form>
                        <p className="form-messege"></p>
                    </div>
                </div>
            </div>
        </div>
        </div>
     <Footer/>
        </div>);
    }
}
export default Contact_Us; 