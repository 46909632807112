import $ from 'jquery';
import React from 'react';
import {CallMapMethod,AjaxCallMethod} from '../function_lib/common_lib';
import {getUserObjData} from '../function_lib/userToken';
import Front_Header from './front_header';
import Footer from './footer';
import AOS from 'aos';

let clsObject;
let cartItems;
let finalAmt=0;
let userObject;
class Step_Two extends React.Component{
    constructor(props){
        super(props);
        cartItems=localStorage.getItem("_cart");
  
        var strList="";
          let finalAmt=0;
        if(cartItems!=null && cartItems!==""){
            cartItems=JSON.parse(cartItems);
        }
        userObject= getUserObjData();
        //alert(userObject[0].id);
    }
    okSuccInv(data,datares){
     // alert("on steptow="+data);
      if(data!=="" && data!=="0"){
        window.location.href="https://adlog.mbdhwellness.com/securePayNow?token="+data;
      }
    }
    getState(){
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getCountry"});
        mapCat.push({name:"res_func",value:"callBackSt"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"p1",value:$("#country").val()});
        mapCat.push({name:"p2",value:"1"});
       // mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat["res_func"]="callBackSt";
        mapCat["curl_type"]="getCountry";
        CallMapMethod(clsObject,mapCat);
    }
    callBackSt(data,msg){
        if(data!=="" && data!=="0"){
            var listState=JSON.parse(data);
            var strState="<option value=\"0\">-Select-</option>";
            for(var i=0;i<listState.length;i++){
                strState+="<option value=\""+listState[i]["location_id"]+"\">"+listState[i]["name"]+"</option>"
            }
            $("#state").html(strState);
        }
    }
    getCountry(){
       
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getCountry"});
        mapCat.push({name:"res_func",value:"callBackCntry"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"p1",value:"0"});
        mapCat.push({name:"p2",value:"0"});
       // mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat["res_func"]="callBackCntry";
        mapCat["curl_type"]="getCountry";
        CallMapMethod(clsObject,mapCat);
    }
    callBackCntry(data,msg){
        if(data!=="" && data!=="0"){
            var listCountry=JSON.parse(data);
            var strCountry="<option value=\"0\">-Select-</option>";
            for(var i=0;i<listCountry.length;i++){
                strCountry+="<option value=\""+listCountry[i]["location_id"]+"\">"+listCountry[i]["name"]+"</option>"
            }
            $("#country").html(strCountry);
        }
       // alert(data);
    }

    componentDidMount(){
        clsObject=this;
        this.getCountry();
       // alert("check data");
       AOS.init();
       AOS.init({
         once: true
       });
       const script = document.createElement("script");
       script.src = "/main.js";
       script.async = true;
       //script.onload = () => clsObject.scriptLoaded();
     
       document.body.appendChild(script);

       AjaxCallMethod(clsObject,"okSuccCNOT");
       //Init 
       $("#loc_token").val(userObject[0].id);
       //$("#amount").val(finalAmt);
       //alert(finalAmt);
    }
    render(){
        return(<div>
             <Front_Header/>
             {/* <!-- checkout area start --> */}
            <div className="checkout-area pt-100px pb-100px">
            <div className="container">
                <div className="row">
                <div className="col-lg-5 mt-md-30px mt-lm-30px ">
                        <div className="border-box your-order-area">
                            <h3>Your order</h3>
                            <div className="your-order-wrap gray-bg-4">
                                <div className="your-order-product-info">
                                    <div className="your-order-top">
                                        <ul>
                                            <li>Product</li>
                                            <li>Total</li>
                                        </ul>
                                    </div>
                                    <div className="your-order-middle">
                                        <ul>
                                        {(cartItems) ? cartItems.map((item,index)=>{
            
            finalAmt+=parseFloat(item.item_rate);
            window.cAmount=finalAmt;
           return <li><span className="order-middle-left">{item.item_name} X 1</span> <span className="order-price">₹{item.item_rate}</span></li>
                                           }):''
                                    }
                                        </ul>
                                    </div>
                                    <div className="your-order-bottom">
                                        <ul>
                                            <li className="your-order-shipping">Shipping</li>
                                            <li>Free shipping</li>
                                        </ul>
                                    </div>
                                    <div className="your-order-total">
                                        <ul>
                                            <li className="order-total">Total</li>
                                            <li>₹{finalAmt}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="payment-method">
                                    <div className="payment-accordion element-mrg">
                                        <div id="faq" className="panel-group">
                                            <div className="panel panel-default single-my-account m-0">
                                                <div className="panel-heading my-account-title">
                                                    <h4 className="panel-title"><a data-bs-toggle="collapse" href="#my-account-1" className="collapsed" aria-expanded="true">Direct bank transfer</a>
                                                    </h4>
                                                </div>
                                                <div id="my-account-1" className="panel-collapse collapse show" data-bs-parent="#faq">
            
                                                    <div className="panel-body">
                                                        <p>Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default single-my-account m-0">
                                                <div className="panel-heading my-account-title">
                                                    <h4 className="panel-title"><a data-bs-toggle="collapse" href="#my-account-2" aria-expanded="false" className="collapsed">Check payments</a></h4>
                                                </div>
                                                <div id="my-account-2" className="panel-collapse collapse" data-bs-parent="#faq">
            
                                                    <div className="panel-body">
                                                        <p>Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default single-my-account m-0">
                                                <div className="panel-heading my-account-title">
                                                    <h4 className="panel-title"><a data-bs-toggle="collapse" href="#my-account-3">Cash on delivery</a></h4>
                                                </div>
                                                <div id="my-account-3" className="panel-collapse collapse" data-bs-parent="#faq">
            
                                                    <div className="panel-body">
                                                        <p>Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-box col-lg-7">
                        <div className="billing-info-wrap">
                        <form>
                        <input type="hidden" name="curl_type" id="curl_type" value="addToInvoice" />  
                <input type="hidden" name="m_type" id="m_type" value="addToInvoice" />
                <input type="hidden" name="loc_token" id="loc_token" />
                <input type="hidden" name="gb_token" id="gb_token" value="5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg" />

                <input type="hidden" name="final_amount" id="final_amount" value={finalAmt} />
                <input type="hidden" name="res_func" id="res_func" value="okSuccInv" />
                            <h3>Billing Details</h3>
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="billing-info mb-4">
                                        <label>Full Name</label>
                                        <input id="name" name="name" type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-select mb-4">
                                        <label>Country</label>
                                        <select className="select_target" resp_dest="getState" id="country" name="country">
                                            <option>Select a country</option>
                                          
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-select mb-4">
                                        <label>State</label>
                                        <select id="state" name="state">
                                           
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-g col-md-6">
                                    <div className="billing-info mb-4">
                                        <label>Town / City</label>
                                        <input id="city" name="city" type="text" />
                                    </div>
                                </div>
                               
                                <div className="col-lg-6 col-md-6">
                                    <div className="billing-info mb-4">
                                        <label>Postcode / ZIP</label>
                                        <input id="pincode" name="pincode" type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-info mb-4">
                                        <label>Street Address</label>
                                        <input className="billing-address" id="address1" name="address1" placeholder="House number and street name" type="text" />
                                        <input placeholder="Apartment, suite, unit etc." id="address2" name="address2" type="text" />
                                    </div>
                                </div>
                            </div>
                          
                         
                            <div className="additional-info-wrap">
                                <h4>Additional information</h4>
                                <div className="additional-info">
                                    <label>Order notes</label>
                                    <textarea placeholder="Notes about your order, e.g. special notes for delivery. " name="message"></textarea>
                                </div>
                            </div>
                            <div id="getRegLogn" style={{color:"red"}}></div>
                            <div className="Place-order mt-25">
                                        <button className="btn btn-primary btn-outline-dark current-btn _btnSetColor" type="button"><span>Place Order</span></button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
             {/* <!-- checkout area end --> */}
           <Footer/>
           </div>
            )
    }
}
export default Step_Two;