import  $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {getAccessRolesNew,CallCMSMapMethod} from '../function_lib/common_cms';
import {RespBkMthdMapp} from '../function_lib/common_lib';
import './front_header.css';
import logoImg from '../assets/images/logo/logo.png';

let listRoles;
let loginUser;
let userObject;

let logInObj;
let userOpt;
let clsObject;

let _topTitle="";
let _topKeywords="";
let _topDescription="";
let loc="";
class Front_Header extends React.Component{
    logout(rf){
        localStorage.clear();
        window.location.href="./";
      }
    constructor(props){
       super(props);
       loc=this.props.loc;
       if(localStorage.getItem("userDS")!=null){
        logInObj=JSON.parse(localStorage.getItem("userDS"));
        
      }
    }
    setMetaTags(listMeta){
        // alert(JSON.stringify(listMeta));
     
         for(var m=0;m<listMeta.length;m++){
             if(typeof loc==='undefined'|| loc===""){
                 loc="home";
             }
             //alert(listMeta[m]["col2"]+"=="+loc);
            if(listMeta[m]["col2"].toUpperCase()===loc.toUpperCase()){
                //alert(listMeta[m]["col2"]);
                _topTitle=listMeta[m]["col3"];
                _topKeywords=listMeta[m]["col4"];
                _topDescription=listMeta[m]["col5"];
                document.title=_topTitle;
             document.querySelector('meta[name="title"]').setAttribute("content", _topTitle);
             document.querySelector('meta[name="description"]').setAttribute("content", _topDescription);
             document.querySelector('meta[name="keywords"]').setAttribute("content", _topKeywords);
            }
           
         }
         
     }
    componentDidMount(){
        clsObject=this;
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getAccessToken"});
        mapCat.push({name:"res_func",value:"callBackAccess"});
       // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
        //mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat["res_func"]="callBackAccess";
        mapCat["curl_type"]="getAccessToken";
        CallCMSMapMethod(this,mapCat);

        userOpt="<button class=\"dropdown-toggle header-action-btn\" data-bs-toggle=\"dropdown\">"+
        "<i class=\"icon-user\"></i></button>"+
                                        "<ul class=\"dropdown-menu dropdown-menu-right\">"+
                                            "<li><a class=\"dropdown-item\" href=\"#\">My Cart</a></li>"+
                                            "<li><a class=\"dropdown-item\" href=\"#\">Checkout</a></li>"+
                                            "<li><a class=\"dropdown-item\" href=\"/login\">Sign in</a></li>"+
                                        "</ul>";
            if(logInObj)
            {
                         userOpt= "<button class=\"dropdown-toggle pr-0 border-0 header-action-btn\" data-bs-toggle=\"dropdown\">"+
                                        "<a href=\"javascript:void(0)\">My Account</a> "+"<i class=\"ion-ios-arrow-down\"></i>"+"</button>"+
                                                                            "<ul class=\"dropdown-menu dropdown-menu-right\">"+
                                                                                "<li><a class=\"dropdown-item\" href=\"#\">"+logInObj[0]["first_name"]+"</a></li>"+
                                                                                "<li><a class=\"dropdown-item\" href=\"#\">My account</a></li>"+
                                                                                "<li><a class=\"dropdown-item\" href=\"#\">Checkout</a></li>"+
                                                                                "<li><a class=\"dropdown-item\" href=\"#\">My Cart</a></li>"+
                                                                                "<li><a class=\"dropdown-item _setbackparam\" param1=\"1\"  href=\"javascript:void(0)\">Logout</a></li>"+
                                                                            "</ul>";   
                                 
                                        }
  //alert(userOpt);
  $("#user_opt_desk").html(userOpt);
  $("#user_opt_mob").html(userOpt);
  RespBkMthdMapp(clsObject,"_setbackparam","logout")

    }
    callBackAccess(data,msg){
       // alert("cback"+data);
        if(data!=="0"){
            localStorage.setItem('userData',data);
            UserToken.setUserObject(localStorage.getItem("userData"));
            listRoles= UserToken.getAccessRoles();
            this.setMetaTags(listRoles);
          var listHtm=  getAccessRolesNew(listRoles); 
          $("#menu_opt").html(listHtm);
          $("#mob_menu").html(listHtm);
        }
      

    }
    render(){
        return(<div><div className="header section">
        <div className="header-top section-fluid bg-black">
            <div className="container">
                <div className="row row-cols-lg-2 align-items-center">
                    
                    <div className="col text-center text-lg-left">
                        <div className="header-top-massege">
                            <p>Welcome to Indore Tour Travels </p>
                        </div>
                    </div>
                    
                    <div className="col d-none d-lg-block">
                        <div className="header-top-set-lan-curr d-flex justify-content-end">
                            
                            <div className="header-top-curr dropdown">
                                <button className="dropdown-toggle" data-bs-toggle="dropdown"> Language : (ENG) <i
                                        className="ion-ios-arrow-down"></i></button>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a className="dropdown-item" href="#">English</a></li>
                               </ul>
                            </div>
                           
                            <div className="header-top-curr dropdown">
                                <button className="dropdown-toggle pr-0 border-0" data-bs-toggle="dropdown">Currency : (INR) <i
                                        className="ion-ios-arrow-down"></i></button>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a className="dropdown-item" href="#">INR ₹</a></li>
                                    <li><a className="dropdown-item" href="#">USD $</a></li>
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
        <div className="header-bottom d-none d-lg-block">
            <div className="container position-relative">
                <div className="row align-self-center">
                   
                    <div className="col-auto align-self-center">
                        <div className="header-logo">
                            <a href="/"><img src={logoImg} alt="Site Logo" /></a>
                        </div>
                    </div>
                    
        
                    
                    <div className="col align-self-center">
                        <div className="header-actions">
                            <div className='header_account_list'>
                                <a href="tel:7746041623">Call Now:7746041623</a>
                            </div>
                            <div className="header_account_list">
                                <a target='_blank' href="https://wa.me/917746041623?send?text=Hi,Indore Tour Travels" className="header-action-btn search-btn"><i
                                        className="ion-social-whatsapp-outline"></i></a>
                               
                            </div>
                            <div className="header_account_list">
                                <a target='_blank' href="https://www.facebook.com/jitendra.porwal.1485" className="header-action-btn search-btn"><i
                                        className="ion-social-facebook-outline"></i></a>
                               
                            </div>
                            <div className="header_account_list">
                                <a target='_blank' href="https://g.co/kgs/Uxpa5V" className="header-action-btn search-btn"><i
                                        className="ion-social-google-outline"></i></a>
                               
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
        
        <div className="header-bottom border-box-2 d-lg-none sticky-nav bg-white">
            <div className="container position-relative">
                <div className="row align-self-center">
                    
                    <div className="col-auto align-self-center">
                        <div className="header-logo">
                            <a href="/"><img src={logoImg} alt="Site Logo" /></a>
                        </div>
                    </div>
                    <div className="col align-self-center">
                        <div className="header-actions">
                        <div className='header_account_list'>
                                <a href="tel:7746041623">Call Now:7746041623</a>
                            </div>
                            <div className="header_account_list">
                                <a target='_blank' href="https://wa.me/917746041623?send?text=Hi,Indore Tour Travels" className="header-action-btn search-btn"><i
                                        className="ion-social-whatsapp-outline"></i></a>
                            
                            </div>
                            <div className="header_account_list">
                                <a target='_blank' href="https://www.facebook.com/jitendra.porwal.1485" className="header-action-btn search-btn"><i
                                        className="ion-social-facebook-outline"></i></a>
                            
                            </div>
                            <div className="header_account_list">
                                <a target='_blank' href="https://g.co/kgs/Uxpa5V" className="header-action-btn search-btn"><i
                                        className="ion-social-google-outline"></i></a>
                            
                            </div>
                            <a href="#offcanvas-mobile-menu" className="header-action-btn header-action-btn-menu offcanvas-toggle d-lg-none">
                                <i className="icon-menu"></i>
                            </a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
        <div className="green d-none d-lg-block sticky-nav">
            <div className="container position-relative">
                <div className="row">
                    <div className="col-md-12 align-self-center">
                        <div className="main-menu manu-color-white">
                            <ul id="menu_opt">
                                <li className="dropdown"><a href="#">Home <i className="ion-ios-arrow-down"></i></a>
                                    <ul className="sub-menu">
                                        <li><a href="index.html">Home 1</a></li>
                                        <li><a href="index-2.html">Home 2</a></li>
                                    </ul>
                                </li>
                                <li><a href="about.html">About us</a></li>
                                <li className="dropdown position-static"><a href="#">Shop <i
                                            className="ion-ios-arrow-down"></i></a>
                                    <ul className="mega-menu d-block">
                                        <li className="d-flex">
                                            <ul className="d-block">
                                                <li className="title"><a href="#">Shop Page</a></li>
                                                <li><a href="shop-3-column.html">Shop 3 Column</a></li>
                                                <li><a href="shop-4-column.html">Shop 4 Column</a></li>
                                                <li><a href="shop-left-sidebar.html">Shop Left Sidebar</a></li>
                                                <li><a href="shop-right-sidebar.html">Shop Right Sidebar</a></li>
                                            </ul>
                                            <ul className="d-block">
                                                <li className="title"><a href="#">product Details Page</a></li>
                                                <li><a href="single-product.html">Product Single</a></li>
                                                <li><a href="single-product-variable.html">Product Variable</a></li>
                                                <li><a href="single-product-affiliate.html">Product Affiliate</a></li>
                                                <li><a href="single-product-group.html">Product Group</a></li>
                                                <li><a href="single-product-tabstyle-2.html">Product Tab 2</a></li>
                                                <li><a href="single-product-tabstyle-3.html">Product Tab 3</a></li>
                                            </ul>
                                            <ul className="d-block">
                                                <li className="title"><a href="#">Single Product Page</a></li>
                                                <li><a href="single-product-slider.html">Product Slider</a></li>
                                                <li><a href="single-product-gallery-left.html">Product Gallery Left</a>
                                                </li>
                                                <li><a href="single-product-gallery-right.html">Product Gallery Right</a>
                                                </li>
                                                <li><a href="single-product-sticky-left.html">Product Sticky Left</a></li>
                                                <li><a href="single-product-sticky-right.html">Product Sticky Right</a></li>
                                            </ul>
                                            <ul className="d-block">
                                                <li className="title"><a href="#">Other Pages</a></li>
                                                <li><a href="cart.html">Cart Page</a></li>
                                                <li><a href="checkout.html">Checkout Page</a></li>
                                                <li><a href="compare.html">Compare Page</a></li>
                                                <li><a href="wishlist.html">Wishlist Page</a></li>
                                                <li><a href="my-account.html">Account Page</a></li>
                                                <li><a href="login.html">Login & Register Page</a></li>
                                                <li><a href="empty-cart.html">Empty Cart Page</a></li>
                                            </ul>
                                        </li>
                                        <li>
        
                                            <ul className="menu-banner w-100">
                                                <li>
                                                    <a className="p-0" href="shop-left-sidebar.html"><img className="img-responsive w-100" src="assets/images/banner/3.jpg" alt="" /></a>
                                                </li>
                                                <li>
                                                    <a className="p-0" href="shop-left-sidebar.html"><img className="img-responsive w-100" src="assets/images/banner/4.jpg" alt="" /></a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className="dropdown"><a href="#">Pages <i className="ion-ios-arrow-down"></i></a>
                                    <ul className="sub-menu">
                                        <li><a href="404.html">404 Page</a></li>
                                        <li><a href="privacy-policy.html">Privacy Policy</a></li>
                                        <li><a href="faq.html">Faq Page</a></li>
                                        <li><a href="coming-soon.html">Coming Soon Page</a></li>
                                    </ul>
                                </li>
                                <li className="dropdown "><a href="#">Blog <i className="ion-ios-arrow-down"></i></a>
                                    <ul className="sub-menu">
                                        <li className="dropdown position-static"><a href="blog-grid-left-sidebar.html">Blog Grid
                                            <i className="ion-ios-arrow-right"></i></a>
                                            <ul className="sub-menu sub-menu-2">
                                                <li><a href="blog-grid-left-sidebar.html">Blog Grid Left Sidebar</a></li>
                                                <li><a href="blog-grid-right-sidebar.html">Blog Grid Right Sidebar</a></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown position-static"><a href="blog-list-left-sidebar.html">Blog List
                                            <i className="ion-ios-arrow-right"></i></a>
                                            <ul className="sub-menu sub-menu-2">
                                                <li><a href="blog-list-left-sidebar.html">Blog List Left Sidebar</a></li>
                                                <li><a href="blog-list-right-sidebar.html">Blog List Right Sidebar</a></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown position-static"><a href="blog-single-left-sidebar.html">Single
                                            Blog <i className="ion-ios-arrow-right"></i></a>
                                            <ul className="sub-menu sub-menu-2">
                                                <li><a href="blog-single-left-sidebar.html">Single Blog Left Sidebar</a>
                                                </li>
                                                <li><a href="blog-single-right-sidebar.html">Single Blog Right Sidebar</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li><a href="contact.html">Contact us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        </div>
        <div id="offcanvas-mobile-menu" className="offcanvas offcanvas-mobile-menu">
        <button className="offcanvas-close"></button>
        <div className="inner customScroll">
        
            <div className="offcanvas-menu mb-4">
                <ul id="mob_menu">
                </ul>
            </div>
        </div>
        </div>
        </div>
        )
    }
}
export default Front_Header;