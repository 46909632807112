import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
export function Article_Listing(lsData){
    var appUrtPath=appGetUrlPath();
var listData=lsData;
    var strHeader="<div class=\"shop-category-area pb-100px pt-70px\">"+
    "<div class=\"container\">"+
        "<div class=\"row\">"+
            "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
            "<div class=\"shop-bottom-area\">"+
            "<div class=\"row\">";
     var strFooter="</div></div></div></div></div></div>";

    var strResp="";
  for(var i=0;i<listData.length;i++){
    var imgPath="default-product.png"; 
    var urlTitle= lsData[i]["col1"];
    urlTitle=urlTitle.replace("?","_");
    urlTitle=urlTitle.replace(" ","-");
    if(listData[i]["col4"]!=""){
     imgPath=listData[i]["col4"];
      }
      strResp+="<div class=\"col-lg-4 col-md-6 col-sm-6 col-xs-6\">"+
      "<div class=\"border-box-2 product mb-5\">"+
      "<form>"+
            "<div class=\"thumb\">"+
              "<a href=\"blog\\"+urlTitle+"?rf="+lsData[i]["e_id"]+"\" class=\"image\">"+
                  "<img src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
                //   "<img class=\"hover-image\" src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
              "</a>"+
          "</div>"+
          "<div class=\"content\">"+
              "<h5 class=\"title\"><a href=\"blog\\"+urlTitle+"?rf="+lsData[i]["e_id"]+"\">"+listData[i]["col1"]+"</a></h5>"+
            //   "<span class=\"price\">"+
            //       "<span class=\"new\">"+listData[i]["col2"]+"</span>"+
            //   "</span>"+
          "</div>"+
      "</form>"+"</div>"+
  "</div>";
  }
  return strHeader+strResp+strFooter;

}
export default Article_Listing;