import React from 'react';
import healthCareImg from '../assets/images/icons/body.png';
import maleWellImg from '../assets/images/icons/male.png';
import eyeWellImg from '../assets/images/icons/eye_wellness.png';
import sexWellImg from '../assets/images/icons/sexual_wellness.png';
import tummyImg from '../assets/images/icons/pregnancyCare.png';
import immunityImg from '../assets/images/icons/immunity.png';
import femaleImg from '../assets/images/icons/female.png';
import liverImg from '../assets/images/icons/liver.png'
import skinImg from '../assets/images/icons/Skin_and_Beauty.png';


import './features_slider.css';

export function Features_Slider(){
    var strResp="";
    strResp+="<div class=\"feature_slider_bg border-box section pt-70px pb-100px\">"+
    "<div class=\"container\">"+
        "<div class=\"category-slider swiper-container\">"+
            "<div class=\"category-wrapper swiper-wrapper\">"+
                
                "<div class=\"swiper-slide\">"+
                    "<a href=\"body_wellness\" class=\"category-inner\">"+
                        "<div class=\"category-single-item feat_img\">"+
                            "<div class=\"feature_img_bg\"><img src=\""+healthCareImg+"\" alt=\"\" /></div>"+
                            "<span class=\"title\">Body Wellness</span>"+
                        "</div>"+
                    "</a>"+
                "</div>"+
                "<div class=\" swiper-slide\">"+
                    "<a href=\"male_wellness\" class=\"category-inner feat_img\">"+
                        "<div class=\"category-single-item\">"+
                            "<div class=\"feature_img_bg\"><img src=\""+maleWellImg+"\" alt=\"\" /></div>"+
                            "<span class=\"title\">Male Wellness</span>"+
                        "</div>"+
                    "</a>"+
                "</div>"+

                "<div class=\"swiper-slide\">"+
                "<a href=\"tummy_wellness\" class=\"category-inner feat_img\">"+
                    "<div class=\"category-single-item\">"+
                        "<div class=\"feature_img_bg\"><img src=\""+tummyImg+"\" alt=\"\" /></div>"+
                        "<span class=\"title\">Tummy Wellness</span>"+
                    "</div>"+
                "</a>"+
            "</div>"+

                "<div class=\" swiper-slide\">"+
                    "<a href=\"eye_wellness\" class=\"category-inner feat_img\">"+
                        "<div class=\"category-single-item\">"+
                            "<div class=\"feature_img_bg\"><img src=\""+eyeWellImg+"\" alt=\"\" /></div>"+
                            "<span class=\"title\">Eye Wellness</span>"+
                        "</div>"+
                    "</a>"+
                "</div>"+
                /*
                "<div class=\" swiper-slide\">"+
                    "<a href=\"sexual_wellness\" class=\"category-inner feat_img\">"+
                        "<div class=\"category-single-item\">"+
                            "<div class=\"feature_img_bg\"><img src=\""+sexWellImg+"\" alt=\"\" /></div>"+
                            "<span class=\"title\">Sexual Wellness</span>"+
                        "</div>"+
                    "</a>"+
                "</div>"+
*/
                "<div class=\" swiper-slide\">"+
                "<a href=\"shop-left-sidebar.html\" class=\"category-inner feat_img\">"+
                    "<div class=\"category-single-item\">"+
                        "<div class=\"feature_img_bg\"><img src=\""+immunityImg+"\" alt=\"\" /></div>"+
                        "<span class=\"title\">Immunity Wellness</span>"+
                    "</div>"+
                "</a>"+
            "</div>"+
             
            "<div class=\" swiper-slide\">"+
            "<a href=\"female_wellness\" class=\"category-inner feat_img\">"+
                "<div class=\"category-single-item\">"+
                    "<div class=\"feature_img_bg\"><img src=\""+femaleImg+"\" alt=\"\" /></div>"+
                    "<span class=\"title\">Female Wellness</span>"+
                "</div>"+
            "</a>"+
        "</div>"+
        "<div class=\" swiper-slide\">"+
        "<a href=\"liver_wellness\" class=\"category-inner feat_img\">"+
            "<div class=\"category-single-item\">"+
                "<div class=\"feature_img_bg\"><img src=\""+liverImg+"\" alt=\"\" /></div>"+
                "<span class=\"title\">Liver Wellness</span>"+
            "</div>"+
        "</a>"+
    "</div>"+
               
            "</div>"+
        "</div>"+
    "</div>"+
"</div>";
//alert("testin g=="+strResp);
return strResp;
}
export default Features_Slider;