import  $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCertUser,RespBkMthdMapp} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import Front_Header from './front_header';
import Single_Product from '../sample_comp/single_product';
import Footer from './footer';
import QueryString from 'query-string';
import AOS from 'aos';

let clsObject;
let rf="0";
let queries;
let proData;
let renderData;
class Product_Landing extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };
setCartBk(refId){
     //alert(refId);
     this.setState(state =>({viewData:refId}));
     this.forceUpdate();
     $("#offcanvas-cart").addClass("offcanvas-open");
   }
  constructor(props){
    super(props);
    this.state={viewData:false};
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    // this.state={viewData:"0"};
    if(props !=null){
      queries = QueryString.parse(this.props.location.search);
            if(queries.rf!=null){
              rf=queries.rf;
            }
    }
  }
    componentDidMount(){
      //alert(rf);
      clsObject=this;
      var mapCat=[];
      mapCat.push({name:"curl_type",value:"getProducts"});
      mapCat.push({name:"res_func",value:"callBackProList"});
      mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
      mapCat.push({name:"p1",value:"0"});
      mapCat.push({name:"p2",value:"0"});
      if(rf!=="0"){
        mapCat.push({name:"rf",value:rf});
      }
     // mapCat.push({name:"emp_id",value:userObject[0].id});
      mapCat["res_func"]="callBackProList";
      mapCat["curl_type"]="getProducts";
      CallMapMethod(clsObject,mapCat);
        //$("#pgComp").html(mainHt);
        AOS.init();
        AOS.init({
          once: true
        });
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
       
    }
    callBackProList(data,msg){
      if(data!=="" && data!=="0"){
        proData=JSON.parse(data);
        this.setState({viewData:true},()=>{
          RespBkMthdMapp(this,"_addToCart","setCartBk");
        });
      }
     
  }
    render(){
        return(<div>
            <Front_Header/>
            
            <div id="pgComp">
               {(this.state.viewData) ?
                <Single_Product proData={proData} /> : ''}
          </div>
    <Footer/>
       </div>)
    }
}
export default Product_Landing;