import $ from 'jquery';
import React from 'react';
import { findDOMNode } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

import Pages from './pages';
import Products from './pages/product_list';
import Product_Landing from './pages/product_landing';
import Step_Two from './pages/step_two';
import Login from './pages/login';
import ContactUs from './pages/contact_us';
//import Blogs from './pages/Blogs_Articles';

//Sample of Component
import AllComp from './sample_comp/all_component';
import Blogs_Page from './pages/blogs_page';
import Blog_Landing from './pages/blog_landing';
class App extends React.Component{
  componentDidMount() {
    // const script = document.createElement("script");    
    // script.async = true;    
    // script.src = "./assets/js/default/active.js"; 
    // document.body.appendChild(script);   
      }
      navigateURI(pathname){
        alert(pathname);
      }
  render(){
    return <Router><Switch>
      <Route exact path="/contactus" component={ContactUs}/>
      {/* <Route exact path="/blogs" component={Blogs_Page} /> */}
      <Route exact path="/blog/:name" component={Blog_Landing} />
      <Route exact path="/products" component={Products}/>
      <Route exact path="/product/:name" component={Product_Landing}/>
      <Route exact path="/Login" component={Login} />
      <Route exact path="/steptwo" component={Step_Two}/>
    <Route exact path="/*" component={Pages} />
  </Switch></Router>
  }
}
export default App;