import React from 'react';
import './home_banner.css';
import { appGetUrlPath } from '../function_lib/common_cms';
export function Home_Banner(lsData){
    var appPath=appGetUrlPath();
    var strResp="";
    var strHeader="<div class=\"hero_home section\">"+
    "<div class=\"hero-slider swiper-container slider-nav-style-1 slider-dot-style-1 dot-color-white\">"+
         "<div class=\"swiper-wrapper\">";
    var strFooter="</div><div class=\"swiper-pagination swiper-pagination-white\"></div>"+
            "<div class=\"swiper-buttons\">"+
        "<div class=\"swiper-button-next\"></div>"+
        "<div class=\"swiper-button-prev\"></div>"+
    "</div>"+
"</div>"+
"</div>";
    for(var i=0;i<lsData.length;i++){
       strResp+="<div class=\"hero-slide-item slider-height-2 swiper-slide d-flex\">"+
       "<div class=\"hero-bg-image\">"+
           "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\"/>"+
       "</div>"+
       "<div class=\"container\">"+
           "<div class=\"row\">"+
            //    "<div class=\"col-md-5 align-self-center\">"+
            //        "<div class=\"hero-slide-content hero-slide-content-2 slider-animated-1 text-center\">"+
            //            "<span class=\"category\">"+lsData[i]["col1"]+"</span>"+
            //            "<p class=\"w-100\">"+lsData[i]["col2"]+"</p>"+
            //             "<a href=\"#\" class=\"btn btn-lg btn-primary btn-hover-dark mt-5\">Shop Now</a>"+
            //        "</div>"+
            //    "</div>"+
           "</div>"+
       "</div>"+
   "</div>";
    }
    return strHeader+strResp+strFooter;
}
export default Home_Banner;