import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
export function Txt_Description(lsData){
    var appPath= appGetUrlPath();
    var strResp="";
    //alert(lsData.length);
    for(var i=0;i<lsData.length;i++){
        
 var stylBgImg="";
 if(lsData[i]["col4"]!==""){
     stylBgImg="style=\"background:url("+appPath+lsData[i]["col4"].trim()+") no-repeat;background-size:cover;background-position:center;min-height:400px\"";
 }   
      strResp+="<div "+stylBgImg+" class=\"border-box privacy_policy_main_area pb-70px pt-70px\">"+
      "<div class=\"container\">"+
          "<div class=\"container-inner\">"+
              "<div class=\"row\">"+
                  "<div class=\"col-12\">"+
                  "<div align=\"center1\">"+
                  "<div class=\"privacy_content section_2\">"+
                  "<h2 class=\"title\">"+lsData[i]["col1"]+"</h2>"+
                  "<p>"+lsData[i]["col2"]+"</p>"+
                  "<p>"+lsData[i]["col3"]+"</p>"+
                  "</div>"+
                  "</div>"+
                  "</div>"+
              "</div>"+
          "</div>"+
      "</div>"+
  "</div>";
    }
    
    return strResp;
}
export default Txt_Description;