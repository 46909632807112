import $ from 'jquery';
import React from 'react';
import Cart_Canvas from '../sample_comp/cart_canvas';
import './footer.css';
let cartItems;
class Footer extends React.Component{
    componentDidMount(){
        cartItems=localStorage.getItem("_cart");
        
        var strList="";
          let finalAmt=0;
        if(cartItems!=null && cartItems!==""){
          cartItems=JSON.parse(cartItems);
          //alert("i am in cart item "+cartItems.length);
          $("._cart_elm_count").html(cartItems.length);
          $("._cart_elm_sun").html("₹"+window.cAmount); 
        }
      }
    render(){
        return(
        <div className="pt-100px">
        <div className="footer-area">
        <div className="footer-container">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-6 col-lg-4 mb-md-30px mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading"><b>Address</b></h4>
                                <p className="about-text1">Indore Tour Travels.<br></br>
                                23,Sector f,Sanwer Road Industrial Area  <br></br>
                                Indore MP - 452003<br></br>
                                <br></br>
                                Call:7746041623<br></br>
                                Email:contact@indoretourtravels.com<br></br>
                                
                                </p>
                                <ul className="link-follow">
                               
                                    <li className="li">
                                        <a className="paypal icon-social-facebook m-0" title="Facebook" href="https://www.facebook.com/jitendra.porwal.1485"></a>
                                    </li>
                                    {/* <li className="li">
                                        <a className="tumblr icon-social-instagram" title="Instagram" href="https://g.co/kgs/Uxpa5V"></a>
                                    </li> */}
                                    <li className="li">
                                        <a className="ion-social-google-outline" title="Google" href="https://twitter.com/home"></a>
                                    </li>
                                    {/* <li className="li">
                                        <a className="pinterest icon-social-youtube" title="Youtube" href="https://www.youtube.com/channel/UCf5kTQ2s5S1xy65f7pg2y8g"></a>
                                    </li> */}
                                   
                                </ul>
                            </div>
                        </div>
                       
                        <div className="col-md-6 col-sm-6 col-lg-3 mb-md-30px mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading"><b>VISITING PLACES</b></h4>
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                            <li className="li"><a className="single-link" href="our_journey">Visting Places</a></li>
                                            <li className="li"><a className="single-link" href="shipping_policy">Temple</a></li>
                                            <li className="li"><a className="single-link" href="privacy_policy">Hill Station</a></li>
                                            <li className="li"><a className="single-link" href="terms_condition">Natural Places</a></li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 col-lg-2 col-sm-6 mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading"><b>SERVICES</b></h4>
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                           <li className="li"><a className="single-link" href="blogs">TAXI BOOKING</a></li>
                                            <li className="li"><a className="single-link" href="faqs">INDIGO</a></li>
                                            <li className="li"><a className="single-link" href="reviews">SWIFT DEZIRE</a></li>
                                            <li className="li"><a className="single-link" href="products">ERTIGA</a></li>
                                            <li className="li"><a className="single-link" href="library">INNOVA</a></li>
                                            <li className="li"><a className="single-link" href="contactus">TEMPO TRAVELLER</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                       
                        <div className="col-md-6 col-lg-3">
                            <div className="single-wedge">
                                <h4 className="footer-herading"><b>FOLLOW US</b></h4>
                                <div className="footer-links">
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                        <li className="li"><a className="single-link" href="shipping_policy">Face Book</a></li>
                                            <li className="li"><a className="single-link" href="privacy_policy">TWITTER</a></li>
                                            <li className="li"><a className="single-link" href="terms_condition">TERMS & CONDITION</a></li>
                                            <li className="li"><a className="single-link" href="return_refund_cancellation_policy">REUTRN,REFUND & CANCELLATION POLICY</a></li>
                                        </ul>
                                    </div>
                                </div>
                                    
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12" style={{paddingTop:"50px",fontSize:"13px"}}>
                        DISCLAIMER: This Site Is Not Intended To Provide Diagnosis, Treatment Or Medical Advice. Products, Services, Information And Other Content Provided On This Site, Including Information That May Be Provided On This Site Directly Or By Linking To Third-Party Websites Are Provided For Informational Purposes Only. Please Consult With A Physician Or Other Healthcare Professional Regarding Any Medical Or Health Related Diagnosis Or Treatment Options. The Results From The Products May Vary From Person To Person. Images shown here are for representation only, actual product may differ.
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row flex-sm-row-reverse">
                        <div className="col-md-6 text-right">
                            <div className="payment-link">
                            <p className="copy-text">Copywrite</p>
                                {/* <img src="assets/images/icons/payment.png" alt="" /> */}
                            </div>
                        </div>
                        <div className="col-md-6 text-left">
                            <p className="copy-text">Indore Tour Travels.</p>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
        </div>
        <a className="chat" href="https://wa.me/917746041623?send?text=Hi,Indore Tour Travels">
<i className="ion-social-whatsapp-outline" style={{fontSize:"2.5rem"}}></i>

           </a>
    <a className="chat-left" href="tel:7746041623">
    <i className="ion-android-call" style={{fontSize:"2.5rem"}}></i>
    
    </a>
        <Cart_Canvas/>
        </div>);
    }
}
export default Footer;