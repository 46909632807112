import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
 import './article_blogs.css';
export function Article_Blogs(lsData){
   // alert(JSON.stringify(lsData));
/*Header Values */
var title="";
if(lsData.length!=0){
title=lsData[0]["g_title"];
}
var appPath=appGetUrlPath();
//var listData=JSON.parse(lsData);
var strResp="";


var strHeader="<div  class=\"main-blog-area pt-70px\">"+
"<div class=\"container\">"+
    "<div class=\"row\">"+
        "<div class=\"col-md-12\">"+
            "<div class=\"heading page__title-bar\">"+
                "<h3 class=\"title\">"+title+"</h3>"+
            "</div>"+
        "</div>"+
    "</div>"+
    "<div class=\"new-product-slider swiper-container slider-nav-style-1\">"+
    "<div class=\"new-product-wrapper swiper-wrapper\">";

var strFooter="</div>"+
"<div class=\"swiper-buttons\">"+
"<div class=\"swiper-button-next\"></div>"+
"<div class=\"swiper-button-prev\"></div>"+
"</div>"+
"</div></div></div>";
for(var i=0;i<lsData.length;i++){
    var urlTitle= lsData[i]["col1"];
    urlTitle=urlTitle.replace("?","_");
    urlTitle=urlTitle.replace(" ","-");
    strResp+="<div class=\"single-blog swiper-slide\">"+
    "<div class=\"blog-image\">"+
        "<a href=\"blog\\"+urlTitle+"?rf="+lsData[i]["e_id"]+"\"><img src=\""+appPath+lsData[i]["col4"]+"\" class=\"img-responsive w-100\" alt=\"\"/></a>"+
    "</div>"+
    "<div class=\"blog-text\">"+
        "<h6 class=\"title\"><a class=\"blog-heading-link\" href=\"blog\\"+urlTitle+"?rf="+lsData[i]["e_id"]+"\">"+lsData[i]["col1"]+"</a></h6>"+
        // "<p class=\"blog-detail-text\">"+lsData[i]["col2"]+"</p>"+
        
    "</div>"+
"</div>";
}
return strHeader+strResp+strFooter;
}
export default Article_Blogs;