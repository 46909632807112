import $ from 'jquery';
import React from 'react';
import {appGetUrlPath,RespBkMthdMapp} from '../function_lib/common_lib';
let proData;
let appUrtPath;
let description;
let composition;
let imgPath;
class Single_Product extends React.Component{
   
      
    constructor(props){
      super(props);
      appUrtPath=appGetUrlPath();
    
      if(this.props.proData!=null){
        proData=this.props.proData;
        imgPath=["default-product.png"];
        //alert(proData[0]["img_path"]);  
        if(proData[0]["img_path"]!==""){
            //alert("i ma in condi");
         imgPath=proData[0]["img_path"].split(',');
          }
        //composition= $.parseHTML();  
       //alert(JSON.stringify(this.props.proData));
      }
    }
    componentDidMount(){
       //$("#composition").html(proData[0]["composition"]);
       $("#pro_faq").html(proData[0]["faq"]);
       $("#pro_description").html(proData[0]["product_description"]);
       $("#pro_usage").html(proData[0]["usage"]);
       $(".product_desc").html(proData[0]["product_description"]);
       
       //alert("i am in click set");
       const script = document.createElement("script");
       script.src = "/main.js";
       script.async = true;
       //script.onload = () => clsObject.scriptLoaded();
     
       document.body.appendChild(script);
    }
    
    render(){
        return(<div><div className="product-details-area pt-50px pb-50px">
        <div className="container">
        <form>
            <div className="row">
    <input type="hidden" name="item_name" value={proData[0]["product_name"]} />
    <input type="hidden" name="item_code" value={proData[0]["product_code"]}/>
    <input type="hidden" name="item_rate" value={proData[0]["cost"]}/>
    <input type="hidden" name="comp_name" value={0} />
    <input type="hidden" name="imgs" value={imgPath[0]} />
    <input type="hidden" name="item_rf_id" value={proData[0]["id"]} />

                <div className="col-lg-4 col-sm-12 col-xs-12 mb-lm-30px mb-md-30px mb-sm-30px">
                    {/* <!-- Swiper --> */}
                    <div className="swiper-container zoom-top">
                        <div className="swiper-wrapper">
                            {imgPath.map(imgNm=>(
   <div className="swiper-slide zoom-image-hover">
   <img className="img-responsive m-auto" src={appUrtPath+imgNm} alt="" />
</div>
                            ))

                            }
                        </div>
                    </div>
                    <div className="swiper-container zoom-thumbs slider-nav-style-1 small-nav mt-3 mb-3">
                        <div className="swiper-wrapper">
                            {imgPath.map(imgNm=>(
 <div className="swiper-slide">
 <img className="img-responsive m-auto" src={appUrtPath+imgNm} alt=""/>
</div>
                            ))}
                           
                           
                        </div>
                      
                        <div className="swiper-buttons">
                            <div className="swiper-button-next"></div>
                            <div className="swiper-button-prev"></div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12 col-xs-12" data-aos="fade-up" data-aos-delay="200">
                    <div className="product-details-content quickview-content">
                        <h2>{proData[0]["product_name"]}</h2>
                        <p className="reference">Reference:<span> {proData[0]["product_code"]}</span></p>
                        <div className="pro-details-rating-wrap">
                            <div className="rating-product">
                                <i className="ion-android-star"></i>
                                <i className="ion-android-star"></i>
                                <i className="ion-android-star"></i>
                                <i className="ion-android-star"></i>
                                <i className="ion-android-star"></i>
                            </div>
                            <span className="read-review"><a className="reviews" href="#">Read reviews (1)</a></span>
                        </div>
                        <div className="pricing-meta">
                            <ul>
                                <li className="old-price not-cut">Rs.{proData[0]["cost"]}</li>
                            </ul>
                        </div>
                        <div className="pro-details-quality">
                            <div className="cart-plus-minus">
                                <input className="cart-plus-minus-box" type="text" name="qtybutton" value="1" />
                            </div>
                            <div className="pro-details-cart">
                                <button type="button" className="add-cart btn _addToCart btn-primary btn-hover-primary ml-4" href="javascript:void(0)"> Add To
                                    Cart</button>
                            </div>
                        </div>
                        <div className="pro-details-wish-com">
                            <div className="pro-details-wishlist">
                                <a href="wishlist.html"><i className="ion-android-favorite-outline"></i>Add to
                                    wishlist</a>
                            </div>
                            <div className="pro-details-compare">
                                <a href="compare.html"><i className="ion-ios-shuffle-strong"></i>Add to compare</a>
                            </div>
                        </div>
                        <div className="pro-details-social-info">
                            <span>Share</span>
                            <div className="social-info">
                                <ul className="d-flex">
                                    <li>
                                        <a href="#"><i className="ion-social-facebook"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="ion-social-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="ion-social-google"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="ion-social-instagram"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12 col-xs-12" data-aos="fade-up" data-aos-delay="200">
                    <div className="description-review-area description-review-bottom" id="pro_usage">
                    
                    </div>
                </div>
            </div>
            </form>
        </div>
    </div>
    <div className="description-review-area pb-100px">
    <div className="container">
        <div className="description-review-wrapper">
            <div className="description-review-topbar nav">
                <a className="active" data-bs-toggle="tab" href="#des-details1">Description</a>
                <a data-bs-toggle="tab" href="#des-details2">FAQs</a>
                <a data-bs-toggle="tab" href="#des-details3">Reviews (2)</a>
            </div>
            <div className="tab-content description-review-bottom">
                <div id="des-details1" className="tab-pane active">
                    <div className="product-description-wrapper">
                        <p id="pro_description">
                        </p>
                        
                    </div>
                </div>
                <div id="des-details2" className="tab-pane">
                    <div className="product-anotherinfo-wrapper" id="pro_faq">
                      
                    </div>
                </div>
                <div id="des-details3" className="tab-pane">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="review-wrapper">
                                <div className="single-review">
                                    <div className="review-img">
                                        <img src="assets/images/review-image/1.png" alt="" />
                                    </div>
                                    <div className="review-content">
                                        <div className="review-top-wrap">
                                            <div className="review-left">
                                                <div className="review-name">
                                                    <h4>White Lewis</h4>
                                                </div>
                                                <div className="rating-product">
                                                    <i className="ion-android-star"></i>
                                                    <i className="ion-android-star"></i>
                                                    <i className="ion-android-star"></i>
                                                    <i className="ion-android-star"></i>
                                                    <i className="ion-android-star"></i>
                                                </div>
                                            </div>
                                            <div className="review-left">
                                                <a href="#">Reply</a>
                                            </div>
                                        </div>
                                        <div className="review-bottom">
                                            <p>
                                                Vestibulum ante ipsum primis aucibus orci luctustrices posuere cubilia Curae Suspendisse viverra ed viverra. Mauris ullarper euismod vehicula. Phasellus quam nisi, congue id nulla.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-review child-review">
                                    <div className="review-img">
                                        <img src="assets/images/review-image/2.png" alt="" />
                                    </div>
                                    <div className="review-content">
                                        <div className="review-top-wrap">
                                            <div className="review-left">
                                                <div className="review-name">
                                                    <h4>White Lewis</h4>
                                                </div>
                                                <div className="rating-product">
                                                    <i className="ion-android-star"></i>
                                                    <i className="ion-android-star"></i>
                                                    <i className="ion-android-star"></i>
                                                    <i className="ion-android-star"></i>
                                                    <i className="ion-android-star"></i>
                                                </div>
                                            </div>
                                            <div className="review-left">
                                                <a href="#">Reply</a>
                                            </div>
                                        </div>
                                        <div className="review-bottom">
                                            <p>Vestibulum ante ipsum primis aucibus orci luctustrices posuere cubilia Curae Sus pen disse viverra ed viverra. Mauris ullarper euismod vehicula.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="ratting-form-wrapper pl-50">
                                <h3>Add a Review</h3>
                                <div className="ratting-form">
                                    <form action="#">
                                        <div className="star-box">
                                            <span>Your rating:</span>
                                            <div className="rating-product">
                                                <i className="ion-android-star"></i>
                                                <i className="ion-android-star"></i>
                                                <i className="ion-android-star"></i>
                                                <i className="ion-android-star"></i>
                                                <i className="ion-android-star"></i>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="rating-form-style">
                                                    <input placeholder="Name" type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="rating-form-style">
                                                    <input placeholder="Email" type="email" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="rating-form-style form-submit">
                                                    <textarea name="Your Review" placeholder="Message"></textarea>
                                                    <button className="btn btn-primary btn-hover-color-primary " type="submit" value="Submit">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
    )
    }
}
export default Single_Product;